
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import StackComponent from '@/components/design-system/layout/StackComponent.vue';

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<stack-component spacing="extra-small">
<p>A Voksmonitor a K-Monitor és a KohoVolit.eu közös projektje, amelynek célja, hogy a választók a politikával ne csak a pártok és jelöltek kommunikációján keresztül találkozzanak, hanem megismerjék a különböző jelöltek programját és álláspontját a legfontosabb európai politikai kérdésekben.</p>
<p>Az alkalmazás a felhasználóhoz rendeli a jelölteket annak sorrendjében, hogy azok szakpolitikai állításai mennyire állnak közel a felhasználó válaszaihoz.</p>
<p>A kérdéseket az alkalmazás elkészítése előtt elküldjük a jelölteknek, akik lehetőséget kapnak a kérdések megválaszolására és válaszaik indoklására. Amennyiben egy jelölt nem válaszol megkeresésünkre, az ő válaszait a választási programja és nyilvános megszólalásai alapján rekonstruáljuk, illetve a meghozott intézkedéseket is alapul vesszük. Ez természetesen felveti annak lehetőségét, hogy a jelöltek hivatalos álláspontjától eltérhetnek a válaszok, ugyanakkor a K-Monitor minden, az alkalmazásban szereplő politikai erőnek megadta a lehetőséget ezek korrigálására.</p>
<p>A Voksmonitor 40 politikai témájú kérdésben segít eligazodni a kitöltőknek. Ezek lefedik az éppen aktuális, európai szinten releváns ügyeket, politikai témákat. A kérdésekre anonim módon, minden esetben igennel, nemmel vagy nem tudommal lehet válaszolni. Minden kérdésnél a felhasználók rendelkezésére áll egy rövid magyarázat is, ahol leíró jelleggel összefoglaltuk a kérdéssel kapcsolatos domináns álláspontokat. Az eredmény minden esetben orientációs jellegű, aminek segítségével a felhasználók össze tudják hasonlítani álláspontjuk a pártok pozíciójával.</p>
</stack-component>
</div>
</template>
